import { cas } from '@/api/api';
import router from '../router';
import store from '../store';
// import Home from '@/views/home/home';
// import { Loading } from 'element-ui';

import { Notification } from 'element-ui';
class Tools {
  static #isLocalStaus = localStorage.getItem('user') ? true : false;
  static #test_user_info = { type: 'test', info: '' };
  constructor() {}

  // 获取url参数
  static getParamsValue(name) {
    let reg = `(^|&)${name}=([^&]*)(&|$)`;
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }

  static isLocal() {
    let status = localStorage.getItem('user') ? true : false;
    return status;
  }

  static getUsetInfo() {
    return JSON.parse(localStorage.getItem('user'));
  }

  static logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(process.env.VUE_APP_CAS_URL + `https://${window.location.host}/`);
  }

  static isLogin(to, from, next, loadingInstance) {
    if (!Tools.getParamsValue('ticket')) {
      // console.log('a');
      window.location.replace(process.env.VUE_APP_CAS_URL + `https://${window.location.host}/`);
    } else if (Tools.isLocal()) {
      // console.log('->刷新');
      loadingInstance.close();
      // 防止无限刷新（逻辑不可用）
      next();
    } else {
      let params = {
        ticket: Tools.getParamsValue('ticket'),
        url: `https://${window.location.host}/`
      };
      cas(params)
        .then((res) => {
          loadingInstance.close();
          if (res.code == '0000') {
            if (res.data.sid == '-1') {
              return Notification({ type: 'error', title: `Error ${res.data.code}`, message: `账号异常` });
            }
            if (res.data.roles.length == 0) {
              return alert('无权限');
            }
            // function test(){console.log(res.data.roles[0].id)}
            // MessageBox.alert(`<v-btn @click=''></v-btn>`, 'HTML 片段', {
            //   dangerouslyUseHTMLString: true
            // });
            store.state.roles = res.data.roles[0].id;
            localStorage.setItem('rolesId', res.data.roles[0].id);
            sessionStorage.setItem('APP_ROLES_ID', res.data.roles[0].id);
            localStorage.setItem('user', JSON.stringify(res.data));
            // console.log('->登录成功');
            router.$addRoutes();
            // store.dispatch('getUserMenuAction').then(() => {
            //   next('/application-record/list');
            // });
          } else if (res.code == '1007') {
            window.location.replace(process.env.VUE_APP_CAS_URL + `https://${window.location.host}/`);
          }
        })
        .catch((err) => {
          loadingInstance.close();
          console.log(err);
        });
    }
  }
}

export default Tools;
