<template>
  <v-container class="del-pb">
    <!-- <AppBar :scroll_data="scroll_data" /> -->

    <!-- 列表 -->
    <v-card
      elevation="2"
      :class="[
        scroll_data.extended
          ? scroll_data.extended && !child_tab.child_visible
            ? 'card-top card-top-scale pa-0'
            : 'card-top pa-4'
          : 'card-bottom pa-4',
        'card-content',
      ]"
      tile
    >
      <span
        @click.stop="_viewToggle({ type: 'list' })"
        class="w-100 d-flex pa-2 px-4 v-card--link child-title text-h5"
        >{{ child_tab.child_title }}</span
      >
      <v-scroll-y-transition hide-on-leave>
        <div v-if="child_tab.child_visible">
          <v-tabs
           
            v-model="toggle_index"
            background-color="#fff"
            color="primary"
            left
            class="border-b"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="nav in list_nav" :key="nav.name">
              {{ nav.name }}
            </v-tab>
          </v-tabs>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pb">
              <v-col cols="4" class="del-pl">
                <v-text-field
                  v-model="search_data.search"
                  single-line
                  class="del-text-field-msg pa-0"
                  outlined
                  label="请输入编号、工程名称、改造地点"
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-btn
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                @click="search"
                >搜索</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-row align-center del-pt del-pb">
              <span class="text-caption">申请时间</span>
              <v-col cols="4" class="del-pr">
                <v-menu
                  ref="search_data_date"
                  v-model="search_date_visible"
                  :close-on-content-click="false"
                  :return-value.sync="search_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      @click:clear="
                        search_date = [];
                        search_data.startDate = '';
                        search_data.endDate = '';
                      "
                      :value="
                        search_date.length > 1
                          ? `${search_date[0]} ～ ${search_date[1]}`
                          : ''
                      "
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      class="del-text-field-msg pa-0"
                      outlined
                      placeholder="请选择时间"
                      dense
                      :clearable="
                        !search_data.startDate && !search_data.endDate
                          ? false
                          : true
                      "
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="zh-cn"
                    v-model="search_date"
                    range
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click.stop="search_date_visible = false"
                    >
                      取消
                    </v-btn>
                    <v-btn text color="primary" @click.stop="_dateConfirm">
                      确定
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
            <v-col class="del-pt">
              <v-btn
                @click.stop="_viewToggle({ type: 'add' })"
                class="px-6"
                color="primary"
                dark
                small
                tile
                elevation="3"
                >新增</v-btn
              >
              <v-btn
                class="ml-4 px-6"
                color="white"
                small
                tile
                elevation="3"
                @click="_delProjectAction"
                :disabled="toggle_index !== 3"
                >删除</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template>
                <div>
                  <v-data-table
                    hide-default-footer
                    @pagination="_paginationAction"
                    :footer-props="{ 'items-per-page-options': [10] }"
                    :server-items-length="server_list_num"
                    no-data-text="暂无数据"
                    v-model="selected_data"
                    :headers="headers"
                    :items="desserts"
                    item-key="project.id"
                    :show-select="toggle_index == 3"
                  >
                    <template v-slot:item.action="{ item }">
                      <v-btn
                        v-if="
                          item.project.auditStatusCode === 0 ||
                          item.project.auditStatusCode === 1
                        "
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="_itemAtcion({ type: 'edit', item: item })"
                        >编辑</v-btn
                      >
                      <v-btn
                        v-if="item.project.auditStatusCode !== 0"
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="
                          _itemAtcion({ type: 'detail', item: item })
                        "
                        >详情</v-btn
                      >
                      <v-btn
                        v-if="
                          item.project.auditStatusCode === 2 && rolesId === 1
                        "
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="error"
                        outlined
                        @click.stop="_itemAtcion({ type: 'over', item: item })"
                        >终止</v-btn
                      >
                      <v-btn
                        v-if="item.project.auditStatusCode === 3"
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="
                          _itemAtcion({ type: 'export', item: item })
                        "
                        >导出</v-btn
                      >
                      <v-btn
                        v-if="item.project.auditStatusCode === 2"
                        class="ml-1"
                        x-small
                        elevation="0"
                        color="accent"
                        outlined
                        @click.stop="
                          _itemAtcion({ type: 'withdraw', item: item })
                        "
                        >撤回</v-btn
                      >
                    </template>
                  </v-data-table>
                </div>
                <BasePageTotal
                  :pageData.sync="pageData"
                  @jumpAction="_pageTotalAction"
                />
              </template>
            </v-col>
          </v-row>
        </div>
      </v-scroll-y-transition>
    </v-card>

    <!-- 详情 -->
    <v-scroll-y-reverse-transition hide-on-leave>
      <v-card
        v-loading="loading"
        v-if="!child_tab.child_visible"
        tile
        elevation="2"
        :class="[
          scroll_data.extended ? 'card-child-top' : 'card-child-bottom',
          'pa-4 card-child-content',
        ]"
      >
        <div class="w-100 d-flex justify-end">
          <v-btn icon @click.stop="_viewToggle({ type: 'list' })">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <v-form ref="project">
            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">项目信息</span></span
                >
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-center"
                v-if="action_type !== 'add'"
              >
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>项目编号</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    readonly
                    v-model="post_data.project.projectSerial"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>工程名称</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.projectName"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>改造地点</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.location"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>改造内容</span
                >
                <v-col cols="3" class="del-p">
                  <v-textarea
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.retrofitContent"
                    class="del-text-field-msg"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-col>

              <!-- 新增 -->
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>申请部门类型</span
                >
                <v-col cols="3" class="del-p">
                  <v-select
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    :items="department_of_application"
                    :label="action_type == 'detail' ? is_check_type : '请选择'"
                    v-model="department_of_application_check"
                    dense
                    solo
                    class="del-text-field-msg pa-0"
                    style="width: 94%"
                  ></v-select>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>
                  <span style="letter-spacing: 3px">申请人部门</span>
                  </span>
                <v-col cols="3" class="del-p">
                  <v-select
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    :items="departmentListName"
                    label="请选择"
                    v-model="departmentName"
                    dense
                    solo
                    class="del-text-field-msg pa-0"
                    style="width: 94%"
                  ></v-select>
                </v-col>
              </v-col>

              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2">
                <span class="red--text">*</span>
                
                <span v-html="applyNameHtml"></span>
                </span>
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.applyName"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>申请人手机号</span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :readonly="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.applyPhone"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-col>


              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span>施工图</span
                >
                <v-col cols="1" class="del-p">
                  <v-file-input
                    v-if="action_type == 'detail' ? false : true"
                    prepend-icon="mdi-file-upload-outline"
                    class="del-input-control del-p del-text-field-msg"
                    :disabled="action_type == 'detail' ? true : false"
                    v-model="work_draw"
                    @change="_setWorkDrawUrlAction"
                    single-line
                    dense
                    truncate-length="15"
                    label="File input"
                  >
                  </v-file-input>
                </v-col>
              </v-col>

              <v-col cols="12" class="del-pt">
                <p
                  class="ma-2"
                  v-for="(item, index) in post_data.fileVos"
                  :key="item.url"
                >
                  <span class="text-caption">{{ item.fileName }}</span>
                  <v-icon class="mx-4 cursor-p" @click="_openAction(item)"
                    >mdi-file-eye-outline</v-icon
                  >
                  <v-icon
                    v-if="action_type == 'detail' ? false : true"
                    class="cursor-p"
                    @click="_delPDFAction(index)"
                    >mdi-delete-outline</v-icon
                  >
                  <span
                    class="
                      text-caption
                      blue--text
                      text-decoration-underline
                      cursor-p
                    "
                    @click="_getDownloadByUrlAndNameAction(item)"
                    >下载</span
                  >
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">项目内容陈述</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <v-expansion-panels popout :value="expansion_index">
                  <v-expansion-panel
                    v-for="item in content_present_list"
                    :key="item.id"
                  >
                    <v-expansion-panel-header>
                      <span class="text-caption">{{ item.name }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p v-if="item.data.length <= 0" class="text-caption">
                        暂无数据
                      </p>
                      <div v-for="child in item.data" :key="child.id">
                        <div
                          v-if="
                            (action_type == 'detail' && child.checkOption) ||
                            action_type == 'edit' ||
                            action_type == 'add'
                          "
                          class="d-flex justify-space-between"
                        >
                          <span class="text-caption"
                            ><span v-if="child.fillFlag === 0" class="red--text"
                              >*</span
                            >{{ child.contentPresent }}</span
                          >
                          <v-radio-group
                            @change="_radioUpdateAction"
                            v-model="child.checkOption"
                            :disabled="action_type == 'detail' ? true : false"
                            :rules="
                              child.fillFlag === 0 ? [rules.required] : []
                            "
                            row
                            class="del-text-field-msg pa-0 del-mt"
                          >
                            <v-radio
                              class="radio-width"
                              :label="child.optionOne"
                              :value="'0'"
                            ></v-radio>
                            <v-radio
                              class="radio-width"
                              :label="child.optionTwo"
                              :value="'1'"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                        <div
                          class="py-2"
                          v-if="
                            child.checkOption === `${child.triggerCondition}` &&
                            `${child.inputFlag}` == '0'
                          "
                        >
                          <v-text-field
                            :disabled="action_type == 'detail' ? true : false"
                            :key="child.id"
                            :rules="
                              child.checkOption === `${child.triggerCondition}`
                                ? [rules.required]
                                : []
                            "
                            v-model="child.inputContent"
                            :placeholder="child.presentation"
                            single-line
                            class="del-text-field-msg pa-0"
                            outlined
                            dense
                            :clearable="action_type != 'detail' ? true : false"
                          ></v-text-field>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex align-center del-pb">
                <span class="text-caption px-2 list-item-subtitle"
                  ><i></i> <span class="subtitle-1">单位信息</span></span
                >
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">使用单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.userDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">造价咨询单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.costConsultDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">设计单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.designDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="d-flex align-center del-pt">
                <span class="text-caption px-2"
                  ><span class="red--text">*</span
                  ><span class="col-label-text">监理单位</span></span
                >
                <v-col cols="3" class="del-p">
                  <v-text-field
                    :disabled="action_type == 'detail' ? true : false"
                    :rules="[rules.required]"
                    v-model="post_data.project.supervisionDept"
                    single-line
                    class="del-text-field-msg pa-0"
                    outlined
                    label="请输入"
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
            <template>
              <v-row
                class="detail-table-style"
                v-for="(item, index) in examine_records_desserts"
                :key="item[index]?item[index].id:index+5645648"
              >
                <v-col cols="12" class="d-flex align-center del-pb">
                  <span class="text-caption px-2 list-item-subtitle"
                    ><i></i>
                    <span class="subtitle-1"
                      >会审记录<span class="red--text font-weight-bold ml-2"
                        >(第 {{ index + 1 }} 轮)</span
                      ></span
                    ></span
                  >
                </v-col>
                <v-col>
                  <template>
                    <div>
                      <v-data-table
                        no-data-text="暂无数据"
                        hide-default-footer
                        dense
                        :headers="examine_records_headers"
                        :items="examine_records_desserts[index]"
                        item-key="id"
                      >
                        <template v-slot:item.auditFlag="{ item }">
                          <span>{{
                            item.auditFlag == 5 ? "审批人撤回" : item.auditFlag
                          }}</span>
                        </template>
                      </v-data-table>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </template>
          </v-form>
          <v-row
            justify="center"
            class="py-4"
            v-if="action_type == 'detail' ? false : true"
          >
            <v-btn
              @click="_setProjectAction(0)"
              small
              tile
              elevation="3"
              class="px-10 py-5"
              :disabled="logadingShow"
              >存草稿</v-btn
            >
            <v-btn
              @click="_setProjectAction(2)"
              color="primary"
              dark
              small
              tile
              elevation="3"
              class="px-10 py-5 ml-4"
              :disabled="logadingShow"
              >提交会审</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import tools from "@/util/tools";
import BasePageTotal from "@/components/base-page-total.vue";
import {
  getProjectList,
  getContentPresentList,
  getProjectDetail,
  setProjectUploadWorkDraw,
  delProject,
  endProject,
  getExportProject,
  getDownloadByUrlAndName,
  getWithdraw,
  getUser,
  getDepartmentList,
  addOrUpdateProjectInformation,
} from "@/api/api";

export default {
  props: ["scroll_data"],
  data() {
    return {
      applyNameHtml:'申&nbsp;&nbsp;&nbsp;&nbsp;请&nbsp;&nbsp;&nbsp;&nbsp;人&nbsp;',
      logadingShow:false,
      loading: false,
      pageData: {
        page: 1,
        server_length: 1,
      },
      testItems: [5, 10],
      rules: {
        required: (value) => !!value || "必填",
      },
      testJson: {
        text: "测试",
      },
      search_data: {}, // 搜索数据
      search_date: [], // 搜索时间
      search_date_visible: false, // 选择时间组件
      toggle_index: 0, //视图nav id
      // 审核状态 0-退回修改 1-会审通过 2-会审中 3-草稿 4-终止
      list_nav: [
        { name: "退回修改" },
        { name: "会审通过" },
        { name: "会审中" },
        { name: "草稿" },
        { name: "已终止" },
      ], // 视图nav
      headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "编号", value: "project.projectSerial", sortable: false },
        { text: "工程名称", value: "project.projectName", sortable: false },
        { text: "改造地点", value: "project.location", sortable: false },
        { text: "申请人", value: "project.createUser", sortable: false },
        { text: "申请时间", value: "project.createTime", sortable: false },
      ], // 列表头
      desserts: [], // 列表数据

      examine_records_headers: [
        {
          text: "审批步骤",
          value: "isInfrastructureName",
          sortable: false,
          width: 200,
        },
        { text: "部门", value: "deptName", sortable: false, width: 200 },
        { text: "会审人", value: "user", sortable: false, width: 100 },
        {
          text: "会审时间",
          value: "meetingAuditTime",
          sortable: false,
          width: 200,
        },
        { text: "会审意见", value: "auditFlag", sortable: false, width: 150 },
        { text: "意见内容", value: "content", sortable: false },
      ], // 会审记录 - 列表头
      examine_records_desserts: [], // 会审记录 - 列表数据

      selected_data: [], // 列表已选数据
      child_tab: {
        child_visible: true,
        child_title: "申请记录",
      }, // 视图
      content_present_list: [], // 陈述列表
      post_data: {
        project: {},
        projectPresents: [],
        fileVos: [],
      }, // 提交数据
      work_draw: [], // 施工图文件
      expansion_index: "", // 陈述列表展开id
      action_type: "list", // 当前操作
      project_id: "", // 操作项目ID
      content_present_list_test: [], // 当前操作
      server_list_num: 0,
      items_per_page: 10,
      userInfo: {}, //用户信息
      departmentName: "", //申请人部门
      department_of_application: ["行政部门", "学院"], //申请部门类型
      departmentList: [], //申请人部门
      department_of_application_check: "学院",
      is_check_type: "",
      nowToggle: "",
      departmentListName: [],
      toggle_indexShow:false,
    };
  },
  /* import引入的组件 */
  components: { BasePageTotal },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {
    nowToggle() {
      // //console.log("inputVal = " + val + " , oldValue = " + oldVal);
      this.pageData.page = 1;
    },
    toggle_index(val, oldVal){
      console.log("inputVal = " + val + " , oldValue = " + oldVal);
      // this.pageData.page=1;
      // this._getProjectList(1);
      // console.log(this.pageData,'this.pageData')
      this.toggle_indexShow=true;
      this._getProjectList(1);
    }
  },
  /* 方法集合 */
  methods: {
    // 获取申请人部门列表
    getDepartmentList() {
      getDepartmentList().then((res) => {
        if (res.code == "0000") {
          this.departmentList = res.data;
          res.data.forEach((item) => {
            this.departmentListName.push(item.dept);
          });
        }
      });
    },
    _paginationAction(data) {
      let self = this;
      self.items_per_page = data.itemsPerPage;
      self._getProjectList(data.page);
    },

    _pageTotalAction(page) {
      let self = this;
      self.pageData.page = page;
      self._getProjectList();
    },

    // 试图切换
    _viewToggle({ type }) {
      let self = this;
      self.child_tab.child_visible = false;
      self.action_type = type;
      self.post_data = {
        project: {},
        projectPresents: [],
        fileVos: [],
      }; // 清空提交数据
      self.project_id = ""; // 项目ID
      self.examine_records_desserts = []; // 会审记录 - 列表数据
      self.work_draw = [];
      switch (type) {
        case "add":
          self.child_tab.child_title = "新增";
          self.post_data.project.userDept = tools.getUsetInfo().departmentName;
          self._getContentPresentList();
          this.getDepartmentList();
          break;
        case "edit":
          self.child_tab.child_title = "编辑";
          self._getContentPresentList();
          // console.log('self.post_data.projectPresents',self.post_data.projectPresents)
          break;
        case "detail":
          self.child_tab.child_title = "详情";
          self._getContentPresentList();
          break;
        case "list":
          self.set_post = {};
          self.child_tab.child_visible = true;
          self.child_tab.child_title = "申请记录";
          self._getProjectList();
          break;
        default:
          break;
      }
    },

    // 陈述选择验证
    _radioVerifyAction() {
      let self = this;
      let radioVerify = true;

      self.content_present_list.forEach((item, index) => {
        if (!radioVerify) {
          return;
        }
        if (item.data.length > 0) {
          let itemStatus = true;
          item.data.forEach((list) => {
            if (!itemStatus) {
              return;
            }
            if (list.fillFlagCn == "是" && !list.checkOption) {
              self.expansion_index = index;
              radioVerify = false;
              self.$notify({
                title: item.name,
                message: `${list.contentPresent}`,
                type: "error",
              });
              itemStatus = false;
            }
          });
        }
      });
      return radioVerify;
    },

    // 日期确认
    _dateConfirm() {
      let self = this;
      self.search_date_visible = false;
      if (self.search_date.length <= 1) {
        this.$confirm("请选择俩个日期", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
      } else {
        self.search_data.startDate = `${self.search_date[0]} 00:00:00`;
        self.search_data.endDate = `${self.search_date[1]} 23:59:59`;
        self.$refs.search_data_date.save(self.search_date);
      }
    },

    // 行操作
    _itemAtcion({ type, item }) {
      let self = this;
      switch (type) {
        case "edit":
          self._viewToggle({ type: type });
          self.project_id = item.project.id;
          // console.log(self.post_data.projectPresents,'self.post_data.projectPresents')
          break;
        case "detail":
          self._viewToggle({ type: type });
          console.log(item.project.id)
          self.project_id = item.project.id;
          break;
        case "over":
          self._endProjectAction({ id: item.project.id });
          break;
        case "export":
          self._downloadPdf({ id: item.project.id });
          break;
        case "withdraw":
          // 撤回
          self._withdrawAction({ id: item.project.id });
          break;

        default:
          break;
      }
    },

    _radioUpdateAction() {
      this.$forceUpdate();
    },

    // 预览文件
    _openAction(file) {
      let self = this;
      if (!file.url) {
        self.$notify({
          title: `无预览文件`,
          type: "error",
        });
      } else {
        window.open(file.url);
      }
    },

    _delPDFAction(index) {
      let self = this;
      self.post_data.fileVos.splice(index, 1);
    },

    /**
     *〈 申请记录 -> 获取项目列表 〉
     */
    search() {
      this.pageData.page = 1;
      this._getProjectList();
    },
    _getProjectList() {
      let self = this;
      let params = {
        // auditStatusCode: self.toggle_index, // 审核状态 0-草稿 1-退回修改 2-会审中 3-会审通过 4-终止
        currentPage: this.toggle_indexShow?'1':self.pageData.page, // 当前页:默认值1
        pageSize: self.items_per_page, // 每页条数:默认值10
        search: self.search_data.search, // 搜索框内容
        startDate: self.search_data.startDate, // 开始时间
        endDate: self.search_data.endDate, // 结束时间
        createTimeSortType: "ASC", // 结束时间
        // todoFlag: 0, // 	1-已办 2-待办
      };
      this.toggle_indexShow=false;
      // 审核状态 0-草稿 1-退回修改 2-会审中 3-会审通过 4-终止
      // 审核状态 0-退回修改 1-会审通过 2-会审中 3-草稿 4-终止
      // //console.log(self.toggle_index, "now");
      this.nowToggle = self.toggle_index;
      switch (self.toggle_index) {
        case 0:
          params.auditStatusCode = 1;
          break;
        case 1:
          params.auditStatusCode = 3;
          break;
        case 2:
          params.auditStatusCode = 2;
          break;
        case 3:
          params.auditStatusCode = 0;
          break;
        case 4:
          params.auditStatusCode = 4;
          break;

        default:
          break;
      }
      //console.log(params);
      getProjectList(params)
        .then((res) => {
          if (res) {
            self.selected_data = [];
            self.desserts = res.data ? res.data : [];
            self.server_list_num = res.total;
            self.pageData.server_length = Math.ceil(res.total / res.pageSize);
            self.pageData.page = res.currentPage;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 内容陈述 -> 获取列表 〉
     */
    _getContentPresentList() {
      let self = this;
      let params = {
        currentPage: 1, // 当前页:默认值1
        pageSize: 999, // 每页条数:默认值10
      };
      self.content_present_list = [];
      getContentPresentList(params)
        .then((res) => {
          if (res.code == "0000") {
            let deptArray = [
              { name: "基建办公室", id: "250", data: [] },
              { name: "总务与空间办公室", id: "238", data: [] },
              { name: "设施设备维护办公室", id: "241", data: [] },
              { name: "安全、健康与环境办公室", id: "242", data: [] },
              { name: "网络信息中心", id: "260", data: [] },
              { name: "校园空间建设部", id: "251", data: [] },
              { name: "总务部", id: "2381", data: [] },
              { name: "后勤保障部", id: "2402", data: [] },
            ];
            res.data.records.forEach((item) => {
              deptArray.forEach((dept) => {
                if (item.deptId == dept.id) {
                  dept.data.push(item);
                }
              });
            });
            self.content_present_list = deptArray;
          }
          // console.log('self.post_data.projectPresents哈哈哈',self.post_data.projectPresents, res.data)
          
        })
        .then(() => {
          let self = this;
          if (self.project_id) {
            self._getProjectDetail({ id: self.project_id });
          }
          console.log(111)
        })
        .catch((err) => {
          //console.log(err);
          self.$notify({
            type: "error",
            title: err.msg,
          });
        });
        
    },

    /**
     *〈 申请记录 -> 详情 〉
     */
    _getProjectDetail({ id }) {
      // console.log(id,'id')
      let self = this;
      let params = { id: id };
      this.getDepartmentList();
      getProjectDetail(params)
        .then((res) => {
          if (res.code == "0000") {
            self.detail_data = res.data;
            self.post_data.project = res.data.project;
            self.post_data.fileVos = res.data.fileVos;
            self.post_data.projectPresents = res.data.projectPresents;
            
            this.departmentName = res.data.project.dwmc;
            res.data.projectPresents.forEach((item) => {
              self.content_present_list.forEach((list) => {
                if (list.data.length > 0) {
                  list.data.forEach((child) => {
                    // console.log(child.id,'child.id')
                    if (
                      item.contentPresentId == child.id &&
                      item.checkOption !== null
                    ) {
                      child.checkOption = item.checkOption.toString();
                      child.inputContent = item.inputContent;
                      // child = Object.assign(child, item);
                    }
                  });
                }
              });
            });
            if (self.action_type == "detail") {
              for (
                let index = 0;
                index < Object.keys(res.data.examineRecords).length;
                index++
              ) {
                res.data.examineRecords[index + 1].forEach((item) => {
                  // //console.log(item)
                  // if (item.isInfrastructure && item.auditFlag != 10) {
                  //   item.auditFlag = "-";
                  // }
                  switch (item.auditFlag) {
                    case 0:
                      item.auditFlag = "通过";
                      break;
                    case 1:
                      item.auditFlag = "不通过";
                      break;
                    case 2:
                      item.auditFlag = "待办";
                      break;
                    //   case 5:
                    // item.auditFlag = "审批人撤回";
                    // break;
                    case 10:
                      item.auditFlag = "基建专员退回";
                      break;
                    case 11:
                      item.auditFlag = "待基建专员审批";
                      break;
                    case 12:
                      item.auditFlag = "-";
                      break;
                    case 15:
                      item.auditFlag = "申请人撤回";
                      break;
                    default:
                      break;
                  }
                  // //console.log(item.auditFlag);
                });
                self.examine_records_desserts.push(
                  res.data.examineRecords[index + 1]
                );
                self.examine_records_desserts.forEach((item) => {
                  item.forEach((item2) => {
                    // //console.log(item)
                    item2.isInfrastructureName =
                      item2.isInfrastructure == "0"
                        ? "会审部门审批"
                        : "基建专员意见";
                    // if (item2.isInfrastructure != "0") {
                    //   item.ggName = item2.user;
                    // }
                  });
                });
              }
            }
            // console.log(this.examine_records_desserts,'self.examine_records_desserts')
            // console.log('self.post_data.projectPresents嘿嘿',self.post_data.projectPresents)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 施工文件上传 〉
     */
    _setWorkDrawUrlAction(file) {
      let self = this;
      if (file.type !== "application/pdf") {
        self.$confirm("请上传PDF格式文件", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
        return;
      }
      let fileData = new FormData();
      fileData.append("file", file);
      self.loading = true;
      self.$notify({
        title: "上传中, 请稍后!",
        type: "info",
      });
      setProjectUploadWorkDraw(fileData)
        .then((res) => {
          self.loading = false;
          if (res.code == "0000") {
            self.$notify({
              title: "文件上传成功",
              type: "success",
            });
            let newFileInfo = {
              fileName: res.data.fileName,
              url: res.data.url,
            };
            self.post_data.fileVos.push(newFileInfo);
          }
        })
        .catch((err) => {
          self.loading = false;
          self.$notify({
            title: "文件上传失败",
            message: err.msg,
            type: "error",
          });
          //console.log(err);
        });
    },

    /**
     *〈 会审申请 〉
     */
    _setProjectAction(type) {
      this.logadingShow = true
      let self = this;
      let params = self.post_data;
      // console.log(this.post_data)
      if (!self.$refs.project.validate()) {
        self.$notify({
          title: `请检查必填项`,
          type: "error",
        });
        this.logadingShow = false
        return;
      } else if (params.fileVos.length == 0) {
        self.$confirm("请上传施工图文件", "操作失败", {
          confirmButtonText: "确定",
          type: "error",
          center: true,
          showClose: false,
          closeOnClickModal: false,
          showCancelButton: false,
        });
        this.logadingShow = false
        return;
      } else if (!self._radioVerifyAction()) {
        this.logadingShow = false
        return;
      }
      // 手动添加参数(必填)
      params.project.auditStatusCode = type; // 审核状态 0-草稿 1-退回修改 2-会审中 3-会审通过 4-终止
      switch (params.project.auditStatusCode) {
        case 0:
          params.project.auditStatusCn = "草稿";
          break;
        case 1:
          params.project.auditStatusCn = "退回修改";
          break;
        case 2:
          params.project.auditStatusCn = "会审中";
          break;
        case 3:
          params.project.auditStatusCn = "会审通过";
          break;
        case 4:
          params.project.auditStatusCn = "终止";
          break;
        default:
          break;
      } //	审核状态（中文）

      //编辑
      console.log('self.post_data.projectPresents嘿嘿',self.post_data.projectPresents)
      if (self.action_type == "edit") {
        // console.log('self.post_data.projectPresents',self.post_data.projectPresents)
        self.post_data.projectPresents.forEach((edit) => {
          self.content_present_list.forEach((item) => {
            // console.log('item.data.length ',item.data.length )
            if (item.data.length > 0) {
              item.data.forEach((list) => {
                console.log(edit.contentPresentId,list.id,'1234')
                // console.log(edit,list.id , edit.contentPresentId,list.id == edit.contentPresentId,'list.id == edit.contentPresentId')
                if (list.id == edit.contentPresentId) {
                  edit.checkOption = list.checkOption;
                  edit.contentPresentId = list.id;
                  edit.inputContent = list.inputContent;
                  // console.log('edit.contentPresentId',edit.contentPresentId)
                }
              });
            }
          });
        });
        params.project.dept = this.departmentName;
        params.project.deptType = this.department_of_application_check;
        // //console.log(this.department_of_application_check)
      }
      //新增
      if (self.action_type == "add") {
        self.content_present_list.forEach((item) => {
          if (item.data.length > 0) {
            item.data.forEach((list) => {
              let new_present = {
                checkOption: list.checkOption,
                contentPresentId: list.id,
                inputContent: list.inputContent,
              };
              params.projectPresents.push(new_present);
            });
          }
        });
        // this.addOrUpdateForm
        params.project.dept = this.departmentName;
        params.project.deptType = this.department_of_application_check;
        // //console.log(params);
      }
      // //console.log(this.department_of_application_check)
      // if (this.department_of_application_check != "学院") {
      //   self.$notify({
      //     title: "行政部门没有基建专员!请重新选择部门类型",
      //     type: "error",
      //   });
      // } else {
        // return
      addOrUpdateProjectInformation(params)
        .then((res) => {
          if (res.code == "0000") {
            self.$notify({
              title: "操作成功",
              type: "success",
            });
            self.post_data = {
              project: {},
              projectPresents: [],
            }; // 清空提交数据
            self._viewToggle({ type: "list" });
            this.logadingShow = false
          } else {
            self.post_data.projectPresents = []; // 清空陈述数据
            this.logadingShow = false
          }
        })
        .catch((err) => {
          console.log(err);
          this.logadingShow = false
        });
      // }
    },

    /**
     *〈 申请记录 -> 删除 〉
     */
    _delProjectAction() {
      let self = this;
      let params = [];
      if (self.selected_data.length == 0) {
        return self.$alert("请选择数据");
      }
      self.selected_data.forEach((item) => {
        params.push(item.project.id);
      });
      self.$msgbox
        .confirm("确认删除?")
        .then(() => {
          delProject(params)
            .then((res) => {
              self.selected_data = [];
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self._getProjectList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //console.log("取消删除");
        });
    },

    /**
     *〈 申请记录 -> 终止 〉
     */
    _endProjectAction({ id }) {
      let self = this;
      let params = { id: id };
      self.$msgbox
        .confirm("确认终止?")
        .then(() => {
          endProject(params)
            .then((res) => {
              if (res.code == "0000") {
                self.$notify({
                  title: "操作成功",
                  type: "success",
                });
                self._getProjectList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //console.log("取消终止");
        });
    },

    /**
     *〈 申请记录 -> 下载审批记录及施工图 〉
     */
    _downloadPdf({ id }) {
      // let self = this;
      let params = { id: id };
      getExportProject(params);
    },

    /**
     *〈 申请记录 -> 撤回 〉
     */
    _withdrawAction({ id }) {
      let self = this;
      let params = { id: id };
      self.$msgbox
        .confirm("确认撤回?")
        .then(() => {
          getWithdraw(params)
            .then((res) => {
              if (res.code == "0000") {
                self.$notify({
                  title: "撤回成功, 详见草稿页!",
                  type: "success",
                });
                self._getProjectList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          //console.log("取消撤回");
        });
    },

    /**
     *〈 下载文件（重命名） 〉
     */
    _getDownloadByUrlAndNameAction(item) {
      getDownloadByUrlAndName(item);
    },
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self.rolesId = parseInt(localStorage.getItem("rolesId"));
    self._getProjectList(1);
    getUser().then((res) => {
      if (res.code == "0000") {
        //console.log(res.data);
        this.userInfo = res.data;
        this.departmentName = res.data.departmentName;
      }
    });
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {},
};
</script>
<style lang="scss">
.detail-table-style {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td {
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    background: #f7f8fc;
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th:last-child {
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
<style lang="scss" scoped></style>
